<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex justify-center">
    <v-card class="card_profile rounded-xl">
      <div class="contacts">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn to="/profile" v-on="on" v-bind="attrs" icon color="warning"
              ><v-icon>mdi-arrow-left-bold</v-icon></v-btn
            >
          </template>
          <span>Back to Profile</span>
        </v-tooltip>
      </div>
      <v-card-text>
        <template v-if="!otherview">
          <div class="datos_profile flex-column">
            <h2>Contacts</h2>
          </div>
          <div class="d-flex justify-end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="goToOtherView('create')"
                  v-on="on"
                  v-bind="attrs"
                  icon
                  color="primary"
                  ><v-icon>mdi-account-plus</v-icon></v-btn
                >
              </template>
              <span>Add Contact</span>
            </v-tooltip>
          </div>
          <div class="datos_secondarys flex-column">
            <v-data-table
              :headers="headers"
              :items="userContacts"
              :items-per-page="5"
              :loading="loadingContact"
              class="elevation-1"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      @click="goToOtherView('edit', item)"
                      v-bind="attrs"
                      icon
                      color="primary"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                  </template>
                  <span>Edit Contact</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      @click="goToDelete(item)"
                      v-bind="attrs"
                      icon
                      color="error"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </template>
                  <span>Delete Contact</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-dialog
              v-model="itemdelete.dialogDelete"
              persistent
              :overlay="false"
              max-width="500px"
              transition="dialog-transition"
            >
              <v-card>
                <v-card-title class="text-h5 white--text blue lighten-2">
                  Delete Contact : {{ itemdelete.name }}
                </v-card-title>
                <v-card-text class="d-flex justify-center text-center mt-3">
                  Are you sure you want to delete the contact?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="loadingContact" text @click="cancel"
                    >Cancel</v-btn
                  >
                  <v-btn
                    :disabled="loadingContact"
                    :loading="loadingContact"
                    text
                    @click="deleteContact"
                    color="error"
                    >Delete</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
        <template v-else>
          <v-spacer></v-spacer>
          <template v-if="typeview == 'create'">
            <v-form v-model="formValid">
              <div class="d-flex justify-center flex-column">
                <h2 class="text-center mb-4">Add Contact</h2>
                <div class="d-flex px-3">
                  <v-text-field
                    outlined
                    dense
                    v-model="itemadd.alias"
                    :rules="[rules.required]"
                    name="alias"
                    label="Name"
                  ></v-text-field>
                </div>

                <div class="d-flex">
                  <div class="col-sm-6">
                    <v-text-field
                      outlined
                      prepend-inner-icon="mdi-numeric-positive-1"
                      v-model="itemadd.number"
                      :rules="[rules.required, rules.phone]"
                      dense
                      name="number"
                      label="Phone"
                    ></v-text-field>
                  </div>
                  <div class="col-sm-6">
                    <v-text-field
                      outlined
                      v-model="itemadd.origin"
                      :rules="[rules.required]"
                      dense
                      name="Origin"
                      label="Origin"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-end">
                <v-btn
                  :disabled="loadingContact"
                  @click="cancel"
                  color="error"
                  class="mx-1"
                  >Cancel</v-btn
                >
                <v-btn
                  :loading="loadingContact"
                  :disabled="loadingContact || !formValid"
                  @click="addContact"
                  color="primary"
                  >Create</v-btn
                >
              </div>
            </v-form>
          </template>
          <template v-if="typeview == 'edit'">
            <div class="d-flex justify-center flex-column">
              <h2 class="text-center mb-4">Edit Contact</h2>
              <div class="d-flex px-3">
                <v-text-field
                  outlined
                  dense
                  v-model="itemadd.alias"
                  :rules="[rules.required]"
                  name="alias"
                  label="Name"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <div class="col-sm-6">
                  <v-text-field
                    outlined
                    prepend-inner-icon="mdi-numeric-positive-1"
                    v-model="itemadd.number"
                    :rules="[rules.required, rules.phone]"
                    dense
                    name="number"
                    label="Phone"
                  ></v-text-field>
                </div>
                <div class="col-sm-6">
                  <v-text-field
                    outlined
                    v-model="itemadd.origin"
                    :rules="[rules.required]"
                    dense
                    name="Origin"
                    label="Origin"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="d-flex justify-end">
              <v-btn
                :disabled="loadingContact"
                @click="cancel"
                color="error"
                class="mx-1"
                >Cancel</v-btn
              >
              <v-btn
                :loading="loadingContact"
                :disabled="loadingContact || formValid"
                @click="editContact"
                color="primary"
                >Edit</v-btn
              >
            </div>
          </template>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import { apiBaseUrl } from "@/enviorment";
import store from "@/store";
import axios, { AxiosError } from "axios";
import formatPhone from "@/utils/formatPhone";
import rules from "@/components/account/rules";
import cleanObject from "@/utils/cleanObject";
export default Vue.extend({
  name: "user-contacts",
  data() {
    return {
      rules: {
        required: rules.required,
        phone: rules.isPhone,
        maxCharsfull: (v) => v.length >= 3 || this.$t("min3Chars"),
      },
      formValid: false,
      itemdelete: {
        uuid: null,
        dialogDelete: false,
        name: "",
      },
      itemadd: {
        alias: "",
        number: "",
        origin: "Lead",
        uuid: null,
      },
      otherview: false,
      typeview: "create",
      srci: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "alias",
        },
        { text: "Phone", value: "number" },
        { text: "Origin", value: "origin" },
        { text: "", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapState(["userContacts", "loadingContact"]),
    /*   invalidAdd() {
      if (
        this.itemadd.alias == null ||
        this.itemadd.alias == "" ||
        this.itemadd.alias == undefined ||
        this.itemadd.number == null ||
        this.itemadd.number == "" ||
        this.itemadd.number == undefined ||
        this.itemadd.origin == null ||
        this.itemadd.origin == "" ||
        this.itemadd.origin == undefined
      ) {
        return true;
      }
      return false;
    }, */
  },
  methods: {
    ...mapMutations(["updateProfile"]),
    ...mapActions([
      "actGetContacts",
      "actCreateContacts",
      "actUpdateContacts",
      "actDeleteContacts",
    ]),

    async getContacts() {
      await this.actGetContacts();
    },

    cancel() {
      this.otherview = false;
      this.typeview = "create";
      this.itemdelete = { uuid: null, dialogDelete: false, name: "" };
      this.itemadd = {
        alias: "",
        number: "",
        origin: "Lead",
      };
    },
    goToDelete(item) {
      this.itemdelete.dialogDelete = true;
      this.itemdelete.uuid = item.uuid;
      this.itemdelete.name = item.alias;
    },
    goToOtherView(type, item) {
      this.otherview = true;
      this.typeview = type;
      if (type == "edit") {
        let num = item.number;
        num = num.replace("+1", "");
        num = num.replaceAll("(", "");
        num = num.replaceAll(")", "");
        num = num.replaceAll("-", "");
        num = num.replaceAll(" ", "");
        num = num.replaceAll("\t", "");
        num = formatPhone(num);

        this.itemadd = {
          alias: item.alias,
          number: num,
          origin: item.origin,
          uuid: item.uuid,
        };
      }
    },
    async editContact() {
      let body = this.itemadd;

      let num = body.number;
      num = num.replace("+1", "");
      num = num.replaceAll("(", "");
      num = num.replaceAll(")", "");
      num = num.replaceAll("-", "");
      num = num.replaceAll(" ", "");
      num = num.replaceAll("\t", "");
      num = formatPhone(num);
      body.number = "+1" + num;
      body = cleanObject(body);
      await this.actUpdateContacts(body);
      this.cancel();
      this.getContacts();
    },
    async addContact() {
      let body = this.itemadd;

      let num = body.number;
      num = num.replace("+1", "");
      num = num.replaceAll("(", "");
      num = num.replaceAll(")", "");
      num = num.replaceAll("-", "");
      num = num.replaceAll(" ", "");
      num = num.replaceAll("\t", "");
      num = formatPhone(num);
      body.number = "+1" + num;
      body = cleanObject(body);
      await this.actCreateContacts(body);
      this.cancel();
      this.getContacts();
    },
    async deleteContact() {
      await this.actDeleteContacts(this.itemdelete.uuid);
      this.cancel();
      this.getContacts();
    },
  },
  async mounted() {
    await this.getContacts();
  },
});
</script>
<style lang="scss" scoped>
.card_profile {
  position: relative !important;

  width: 100%;
}

.actions {
  position: absolute !important;

  top: 0;
  right: 0;
}
.contacts {
  position: absolute !important;

  top: 0;
  left: 0;
}

.datos_profile {
  display: flex;
  justify-content: center;
  text-align: center;
  justify-items: center;
}
.datos_profile_edit {
  display: flex;
  justify-content: center;
  text-align: center;
  justify-items: center;
}
.datos_secondarys {
  margin-top: 10px;
  width: 100% !important;
  display: flex;
  justify-content: center;
  text-align: center;
  justify-items: center;
}
</style>
<i18n>
{
  "en": {   
    "min3Chars": "Minimum 3 characters"
  },
  "es": {   
    "min3Chars": "Mínimo 3 caracteres"  
  }
}
</i18n>
